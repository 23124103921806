import envConfig from "../environment-property/property.json";
import axios from "axios";
import AuthService from "./AuthService";
import jwt from "jwt-decode";

const host = envConfig.services.host;

export function setAccessToken(accessToken) {
  const decoded = jwt(accessToken);

  let loggedUser = {
    name: decoded.name,
    userName: decoded.unique_name,
  };

  localStorage.setItem("logged-user", JSON.stringify(loggedUser));
  localStorage.setItem(envConfig.authConfiguration.tokenCacheKey, accessToken);
}

export async function validateAccessToken(accessToken) {
  if (Date.now() >= jwt(accessToken).exp * 1000) {
    let params = new URL(document.location).searchParams;
    if (params.get("source") === "empower") {
      throw new Error("TOKEN_NOT_ELIGIBLE_FOR_REFRESH");
    } else {
      const token_1 = await new AuthService().getToken();
      setAccessToken(token_1);
      return token_1;
    }
  } else {
   
    return new Promise((resolve) => {
      resolve(accessToken);
    });
  }
}

export async function generateHeaderDetails(accessToken, subscriptionKey) {
  const validatedAccessToken = await validateAccessToken(accessToken);
  return {
    headers: {
      Authorization: "Bearer " + validatedAccessToken,
      "Ocp-Apim-Subscription-Key": subscriptionKey,
    },
  };
}

export function getloggedUserDetails() {
  return JSON.parse(localStorage.getItem("logged-user"));
}
// axios.get( envConfig.services.host +envConfig.services.projectConfiguration + "?config-type=TASK&project-id=" +this.props.gteProject, this.state.configValue )

export async function getRequestMethod(
  projectConfiguration,
  parameter1,
  value1,
  parameter2,
  value2
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  let parameter3 = "&logged-user-name=";
  let value3 = getloggedUserDetails().userName;
  return await axios.get(
    host +
      projectConfiguration +
      parameter1 +
      value1 +
      parameter2 +
      value2 +
      parameter3 +
      value3,
    config
  );
}

// axios.patch( envConfig.services.host + envConfig.services.projectConfiguration + "/" + project + "/" + configId,value, this.state.configValue )
// axios.patch( envConfig.services.host +envConfig.services.projectConfigurationCore + "/delegation",              value,this.state.configValue )

export async function patchMethod(
  projectConfiguration,
  parameter0,
  value0,
  parameter1,
  value1,
  parameter2,
  value2,
  value3
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  return await axios.patch(
    host +
      projectConfiguration +
      parameter0 +
      value0 +
      parameter1 +
      value1 +
      parameter2 +
      value2,
    value3,
    config
  );
}

// .post( envConfig.services.host + envConfig.services.projectConfigurationCore + "/delegation",value, this.state.configValue)
export async function postMethod(
  projectConfiguration,
  parameter1,
  value1,
  parameter2,
  value2,
  value3
) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey
  );

  return await axios.post(
    host + projectConfiguration + parameter1 + value1 + parameter2 + value2,
    value3,
    config
  );
}

export function getErrorMessage(error) {
  let value =
    "Opps! An Unexpected Error Occured. Please Reach out to EAG Support Team with a Screenshot of this error.  Request ID : ";

  let message = {};
  let requestId = "Not Applicable";
  if (error?.response) {
    requestId = getRequestId(error?.response);

    if (error?.response?.status === 404) {
      message = {
        message: "No Data To Display",
        status: error?.response?.status,
      };
    } else if (error.response?.status === 401) {
      message = {
        status: error.response?.status,
        message: {
          message: value + " " + requestId,
          status: error.response?.status,
        },
      };
    } else {
      message = {
        message: value + " " + requestId,
        status: error?.response?.status,
      };
    }
  } else if (error?.request) {
    requestId = getRequestId(error?.request);

    message = {
      message: value + " " + requestId,
      status: error?.response?.status,
    };
  } else if(error?.message === "TOKEN_NOT_ELIGIBLE_FOR_REFRESH"){
    message = {
      message: "Sorry your session expired. Please refresh the page to continue",
      status: 0,
    };
  }
  else {
    message = {
      message: value + " " + requestId,
      status: 0,
    };
  }

  return message;
}

function getRequestId(value) {
  let requestId = "Not Applicable";
  if (value?.hasOwnProperty("data")) {
    if (value?.data?.hasOwnProperty("message")) {
      var str_pos = value?.data?.message?.indexOf("RequestID");
      if (str_pos > -1) {
        requestId = value?.data?.message?.slice(
          value?.data?.message?.indexOf(":") + 1
        );
      }
    }
  }
  return requestId;
}
export async function postFeedback(user_id, value) {
  const config = await generateHeaderDetails(
    localStorage.getItem(envConfig.authConfiguration.tokenCacheKey),
    envConfig.securityConfiguration.OcpApimSubscriptionKey_for_feedback
  );

  return await axios.post(
    // envConfig.services.feedback_host +
    // envConfig.services.virtusa_generic_feedback_crud_api +
    // envConfig.services.feedback +
    // envConfig.services.user_feedback,
    envConfig.services.user_feedback_uri,
    value,
    config
  );
}

export const sendFeedback = (user_id, value) => {
  return new Promise((resolve) => {
    resolve(
      postFeedback(user_id, value).then((res) => {
        return res.data;
      })
    );
  });
};
