import envConfig from "../../environment-property/property.json";
import React, { Component } from "react";
import {
  getErrorMessage,
  getRequestMethod,
  postMethod,
} from "../../services/utils";
import ErrorModel from "../commonComponents/errorModel";

class ChangeTimeApprover extends Component {
  state = {
    teamMembers: [],
    allSelected: false,
    isChanged: false,
    tabelRefresh: false,
    approver: 0,
    approversList: [],
    error: {
      status: false,
      message: "",
    },
    focus: false,
    isDropdownFocused: false,
  };

  componentDidMount() {
    this.getChangeTimeApproverData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.gteProject !== this.props.gteProject) {
      this.getChangeTimeApproverData();
    }
    if (prevProps.move !== this.props.move) {
      if (this.props.move) {
        this.setState({
          focus: true,
        });
      }
    }
  }

  getChangeTimeApproverData = () => {
    //Should pass project ID {this.props.gteProject}
    // console.log(this.props.gteProject);
    this.setState({
      teamMembers: [],
      tabelRefresh: false,
      error: {
        status: false,
        message: "",
      },
    });

    getRequestMethod(
      envConfig.services.projectConfigurationBFF,
      "?delegation-type=A&project-id=",
      this.props.gteProject,
      "",
      ""
    )
      .then((response) => {
        this.setState({
          teamMembers: response.data.teamMembers?.map((member) => {
            let defaultValue = {};
            member.delegatees?.map((obj) => {
              if (obj.isSelected === "Y") {
                defaultValue = obj.gteEmployeeNo;
              }
            });
            return {
              ...member,
              defaultValue: defaultValue,
              isEnable: false,
              isChanged: false,
              refreshing: false,
            };
          }),
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  getBulkApproverList = () => {
    if (this.state.isChanged) {
      this.setState({
        approversList: [],
        approver: 0,
        error: {
          status: false,
          message: "",
        },
      });

      getRequestMethod(
        envConfig.services.projectConfigurationBFF,
        "-bulk?delegation-type=A&project-id=",
        this.props.gteProject,
        "",
        ""
      )
        .then((response) => {
          this.setState({
            approver: 0,
            approversList: response.data.approvers,
          });
        })
        .catch((error) => {
          let message = getErrorMessage(error);
          this.setState({
            error: {
              status: true,
              message: message,
            },
          });
        });
    }
  };

  handleAllSelectedCheckBoxChangeEvent = (event) => {
    this.setState({
      allSelected: event.target.checked,
      isChanged: event.target.checked,
      teamMembers: this.state.teamMembers.map((obj) => {
        return {
          ...obj,
          isEnable: event.target.checked,
          isChanged: event.target.checked,
        };
      }),
      tabelRefresh: true,
    });
  };

  handleCheckBoxChangeEventofItem = (e, gteEmployeeNo) => {
    this.setState({
      isChanged: true,

      teamMembers: this.state.teamMembers.map((obj) => {
        if (obj.gteEmployeeNo === gteEmployeeNo) {
          return { ...obj, isEnable: e.target.checked, isChanged: true };
        }
        return obj;
      }),
    });

    if (this.state.allSelected === true) {
      this.setState({
        allSelected: false,
      });
    }
  };
  timeApproverDelegationSave = (value) => {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });

    postMethod(
      envConfig.services.projectConfigurationBFFPOST,
      "/delegation",
      "",
      "",
      "",
      value
    )
      .then((response) => {})
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  handleChangeCurrentTimeApprover(
    e,
    removedApprovergteEmployeeNo,
    teamMemberGteEmployeeId
  ) {
    this.setState({
      teamMembers: this.state.teamMembers.map((member) =>
        member.gteEmployeeNo === teamMemberGteEmployeeId
          ? { ...member, defaultValue: e.target.value }
          : member
      ),
    });
    if (e.target.value !== removedApprovergteEmployeeNo) {
      let value = {
        loggedUser: this.props.loggedUser.email,
        delegation: "A",
        projectId: this.props.gteProject,
        teamMemberGteEmployeeId: parseInt(teamMemberGteEmployeeId),
        delegatees: [
          {
            delegationType: "D",
            gteEmployeeNos: [removedApprovergteEmployeeNo],
          },
          {
            delegationType: "A",
            gteEmployeeNos: [parseInt(parseInt(e.target.value))],
          },
        ],
      };

      this.timeApproverDelegationSave(value);
    }
  }

  saveApprovalBulk = () => {
    let team = [];
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });

    if (this.state.isChanged && this.state.approver !== 0) {
      this.state.teamMembers?.map((member) => {
        if (member.isEnable && member.isChanged) {
          team = [...team, member.gteEmployeeNo];
        }
      });

      let value = {
        projectId: this.props.gteProject,
        loggedUser: this.props.loggedUser.email,
        delegationType: "A",
        teamMembers: team,
        delegatees: [parseInt(this.state.approver)],
      };

      postMethod(
        envConfig.services.projectConfigurationBFFPOST,
        "/delegation",
        "",
        "",
        "",
        value
      ).then((response) => {
        let teamMembers = this.state.teamMembers?.map((member) => {
          if (member.isEnable && member.isChanged) {
            return {
              ...member,
              defaultValue: this.state.approver,
              isEnable: false,
              isChanged: false,
              refreshing: false,
            };
          } else {
            return member;
          }
        });

        this.setState({
          allSelected: false,
          isChanged: false,
          teamMembers: teamMembers,
          approver: 0,
          approversList: [],
        });
      });
    } else {
      let teamMembers = this.state.teamMembers?.map((member) => {
        if (member.isEnable && member.isChanged) {
          return {
            ...member,
            isEnable: false,
            isChanged: false,
            refreshing: false,
          };
        } else {
          return member;
        }
      });
      this.setState({
        teamMembers: teamMembers,
        allSelected: false,
        isChanged: false,
      });
    }
  };
  cancelApprovalBulk = () => {
    this.setState({
      approver: 0,
      approversList: [],
      allSelected: false,
      isChanged: false,
      teamMembers: this.state.teamMembers.map((obj) => {
        return { ...obj, isEnable: false, isChanged: false, refreshing: false };
      }),
    });
  };
  setRefreshingTrue = () => {
    if (this.state.isChanged) {
      let teamMembers = this.state.teamMembers?.map((member) => {
        if (member.isEnable && member.isChanged) {
          return {
            ...member,
            refreshing: true,
          };
        } else {
          return member;
        }
      });

      this.setState({
        teamMembers: teamMembers,
      });
    }
  };
  handleDropdownFocus = () => {
    this.setState({ isDropdownFocused: true });
  };

  handleDropdownBlur = () => {
    this.setState({ isDropdownFocused: false });
  };
  renderModelForRemoveAll = () => {
    return (
      <div className="button-with-model">
        {/* <!-- Button trigger modal --> */}
        <button
          tabIndex={0}
          ref={(inputElement) => {
            if (inputElement) {
              if (this.state.focus) {
                inputElement.focus();
              }
            }
          }}
          onKeyDown={() => {
            this.setState({
              focus: false,
            });
            this.props.updateMoveState();
          }}
          type="button"
          className="bulk-config-btn"
          data-bs-toggle="modal"
          data-bs-target="#approvalBulkModal"
          onClick={() => {
            this.setState({ approver: 0 });
            this.getBulkApproverList();
            this.setRefreshingTrue();
          }}
          title="Approval Bulk"
        >
          Approval Bulk
        </button>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="approvalBulkModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header  bg-dark">
                <h6
                  className="modal-title text-light"
                  tabIndex={0}
                  title="Change the Time Approver of selected"
                  role="presentation"
                >
                  Change the Time Approver of selected
                </h6>
                <button
                  type="button"
                  tabIndex={0}
                  className="btn-close bg-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.cancelApprovalBulk();
                  }}
                ></button>
              </div>
              {this.state.isChanged ? (
                <div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-4">
                        {" "}
                        <h5 tabIndex={0} title="Time Approver">
                          Time Approver
                        </h5>
                      </div>
                      <div className="col-8">
                        <select
                          className="col-12"
                          value={this.state.approver}
                          onChange={(e) => {
                            this.setState({
                              approver: parseInt(e.target.value),
                            });
                          }}
                        >
                          <option value="0"></option>
                          {this.state.approversList?.map((approver) => {
                            return (
                              <option
                                key={approver.gteEmployeeNo}
                                value={approver.gteEmployeeNo}
                              >
                                {approver.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="popup-btn"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        this.saveApprovalBulk();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <div className="modal-body">
                  <h5
                    className="text-danger text-center"
                    role="presentation"
                    tabIndex={0}
                    title="You have not selected any Team Member"
                  >
                    <span>You have not selected any Team Member</span>
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <section>
          <div className="col-11"></div>

          {/* <div className="row">
            <div className="col-11">
           
            </div>
            <div className="col-1">
              <button type="button" className="btn btn-outline-dark">
                Save
              </button>
            </div>
          </div> */}

          <section>
            <div className="row">
              <div className="col-10"></div>
              <div className="col-2 align-right">
                {this.renderModelForRemoveAll()}
              </div>
            </div>

            <div className="row select-all">
              <div key="a1" className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.allSelected}
                  id="allSelectedCheckbox"
                  onChange={(e) => this.handleAllSelectedCheckBoxChangeEvent(e)}
                  title={`${
                    this.state.allSelected
                      ? "Selected all Team Members"
                      : "Unselected all Team Members"
                  }`}
                />
                <label className="form-check-label">All</label>
              </div>
            </div>

            <div className="row md-table">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="" tabIndex={0}>
                      Team Member
                    </th>
                    <th className="" tabIndex={0}>
                      Current Time Approver
                    </th>
                  </tr>
                </thead>
                {this.state.teamMembers.length !== 0 ? (
                  <tbody>
                    {this.state.teamMembers?.map((member) => {
                      return (
                        <tr key={member.gteEmployeeNo}>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={member.isEnable}
                                title={`${
                                  member.isEnable ? "Selected" : "Unselect"
                                } ${member.name}`}
                                id={member.gteEmployeeNo}
                                onChange={(e) =>
                                  this.handleCheckBoxChangeEventofItem(
                                    e,
                                    member.gteEmployeeNo
                                  )
                                }
                              />
                              <label className="form-check-label-input">
                                <b>{member.employeeNo}</b> {" - "}
                                {member.name}
                                {member.isActive === "N" ? "(Ex.)" : ""}
                              </label>
                            </div>
                          </td>
                          <td>
                            {member.refreshing ? (
                              <div className="spinner-container">
                                <div className="loading-spinner"></div>
                              </div>
                            ) : (
                              <div className="appvr-dropdown">
                                <select
                                  key={member.gteEmployeeNo}
                                  value={member.defaultValue}
                                  onFocus={this.handleDropdownFocus}
                                  onBlur={this.handleDropdownBlur}
                                  title={`${
                                    !this.state.isDropdownFocused
                                      ? "Expand"
                                      : "Collapse"
                                  } Current Time Approver list`}
                                  onChange={(e) =>
                                    this.handleChangeCurrentTimeApprover(
                                      e,
                                      member.defaultValue,
                                      member.gteEmployeeNo
                                    )
                                  }
                                >
                                  {member.delegatees?.map((delegatee) => {
                                    return (
                                      <option
                                        key={delegatee.gteEmployeeNo}
                                        value={delegatee.gteEmployeeNo}
                                        title={delegatee.name}
                                      >
                                        {delegatee.name}
                                        {/* {delegatee.gteEmployeeNo} */}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <div className="notify">Data Fetching</div>
                )}
              </table>
            </div>
          </section>
        </section>
        {this.state.error?.status &&
          this.state.error.message.status !== 404 && (
            <ErrorModel
              loggedUser={this.props.loggedUser}
              errorResponse={this.state.error?.message}
              hide={() => {
                this.setState({
                  error: {
                    status: false,
                    message: "",
                  },
                });
              }}
            ></ErrorModel>
          )}
      </React.Fragment>
    );
  }
}

export default ChangeTimeApprover;
