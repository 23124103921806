import React, { Component } from "react";
import envConfig from "../environment-property/property.json";
import Time from "./menuComponents/time";
import Task from "./menuComponents/task";
import Comment from "./menuComponents/comment";
import ManageDelegates from "./menuComponents/manageDelegates";
import ChangeTimeApprover from "./menuComponents/ChangeTimeApprover";
import Feedback from "./utilComponents/feedback";
import Banner from "./utilComponents/banner";

import TimeIcon from "../logo/clock.png";
import TaskIcon from "../logo/task.png";
import CommentIcon from "../logo/comment.png";
import MngIcon from "../logo/delegate.png";
import TimeApIcon from "../logo/approve.png";
import { getErrorMessage, getRequestMethod } from "../services/utils";
import ErrorModel from "./commonComponents/errorModel";
// import { getRequestMethod } from "../utils";

class MainMenu extends Component {
  state = {
    projects: [],
    selectedMenu: "Time",
    selectedGTEProjectId: 0,
    loading: true,
    error: {
      status: false,
      message: "",
    },
    projectTypeConfig: [],
    projectTemplate: [],
    selectedProject: {},
    toast: true,
    // bannerVisible: !document.cookie.includes("chk_pc_info_banner=true"),
    // feedbackVisible: !document.cookie.includes("chk_pc_feedback=true"),
    bannerVisible: envConfig.banner.showBanner,
    feedbackVisible: envConfig.showFeedback,
    move: false,
    isDropdownFocused: false,
  };

  componentDidMount() {
    this.getAllocatedProjectList();
    this.getProjectTypeConfig();
    this.getProjectTemplate();
  }

  componentDidUpdate(prevProps, prevState) {}

  handleProjectDropdownChange = () => {};

  getProjectTemplate = () => {
    // https://checkin-api-dev1.virtusa.com/checkin-source-service/config?config-type=PROCESS_TEMPLATE
    getRequestMethod(
      envConfig.services.generalConfigType,
      "?config-type=",
      "PROCESS_TEMPLATE",
      "",
      ""
    )
      .then((response) => {
        this.setState({
          projectTemplate: response?.data,
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status: message?.status === 401 ? false : true,
            message: message,
          },
        });
      });
  };
  getProjectTypeConfig = () => {
    // https://checkin-api-dev1.virtusa.com/checkin-source-service/config?config-type=LEAD_BY
    getRequestMethod(
      envConfig.services.generalConfigType,
      "?config-type=",
      "LEAD_BY",
      "",
      ""
    )
      .then((response) => {
        this.setState({
          projectTypeConfig: response?.data,
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        // console.log("error ", message?.message)
        this.setState({
          loading: false,
          error: {
            status: message?.status === 401 ? false : true,
            message: message,
          },
        });
      });
  };

  getAllocatedProjectList = () => {
    getRequestMethod(
      envConfig.services.allocatedProjectByEmployee,
      "&user-name=",
      this.props.loggedUser.email,
      "",
      ""
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          this.setState({
            loading: false,
            projects: response?.data,
            selectedGTEProjectId: response?.data[0]?.gteProjectId,
            selectedProject: response?.data[0],
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status: message?.status === 401 ? false : true,
            message: message,
          },
        });
      });
  };

  bindListGroupClasses = (menuName) => {
    const listClass = "list-group-item list-group-item-action p-2";

    if (this.state.selectedMenu === menuName) {
      return listClass + " active";
    } else {
      return listClass;
    }
  };

  onClickMenuItem = (menuName) => {
    this.setState({ selectedMenu: menuName });
  };
  updateMoveState() {
    this.setState({
      move: false,
    });
  }
  renderConfigMenu = () => {
    if (!this.state.loading) {
      if (this.state.projects.length > 0) {
        switch (this.state.selectedMenu) {
          case "Time":
            return (
              <Time
                gteProject={this.state.selectedGTEProjectId}
                loggedUser={this.props.loggedUser}
                move={this.state.move}
                updateMoveState={() => {
                  this.updateMoveState();
                }}
              />
            );
            break;
          case "Task":
            return (
              <Task
                gteProject={this.state.selectedGTEProjectId}
                loggedUser={this.props.loggedUser}
                projectTemplate={this.state.projectTemplate}
                projectTypeConfig={this.state.projectTypeConfig}
                selectedProject={this.state.selectedProject}
                move={this.state.move}
                updateMoveState={() => {
                  this.updateMoveState();
                }}
              />
            );
            break;
          case "Comment":
            return (
              <Comment
                gteProject={this.state.selectedGTEProjectId}
                loggedUser={this.props.loggedUser}
                move={this.state.move}
                updateMoveState={() => {
                  this.updateMoveState();
                }}
              />
            );
            break;
          case "Manage Delegates":
            return (
              <ManageDelegates
                gteProject={this.state.selectedGTEProjectId}
                loggedUser={this.props.loggedUser}
                move={this.state.move}
                updateMoveState={() => {
                  this.updateMoveState();
                }}
              />
            );
            break;
          case "Change Time Approver":
            return (
              <ChangeTimeApprover
                gteProject={this.state.selectedGTEProjectId}
                loggedUser={this.props.loggedUser}
                updateMoveState={() => {
                  this.updateMoveState();
                }}
              />
            );
            break;
          default:
          // code block
        }
      } else {
        if (
          this.state.error?.status &&
          this.state.error?.message?.status === 404
        ) {
          return (
            <div className="notify">{this.state.error?.message?.message}</div>
          );
        } else {
          return <div className="notify">No Data to Display</div>;
        }
      }
    }
    {
      return <div className="notify">Loading..</div>;
    }
  };

  handleProjectDropDownChange = (event) => {
    this.setState({ selectedGTEProjectId: parseInt(event.target.value) });
    this.getSelectedProject(parseInt(event.target.value));
  };

  getSelectedProject = (selectedGTEProjectId) => {
    let selectedProject;

    if (this.state.projects.length > 0) {
      this.state.projects?.map((project) => {
        if (project.gteProjectId === selectedGTEProjectId) {
          selectedProject = project;
        }
      });

      this.setState({
        selectedProject: selectedProject,
      });
    }
  };

  handleDropdownFocus = () => {
    this.setState({ isDropdownFocused: true });
  };

  handleDropdownBlur = () => {
    this.setState({ isDropdownFocused: false });
  };

  render() {
    const { bannerVisible, feedbackVisible } = this.state;

    return (
      <React.Fragment>
        {bannerVisible ? (
          <Banner
            bannerVisible={(value) => {
              this.setState({ bannerVisible: value });
            }}
          />
        ) : (
          <div>
            {feedbackVisible && (
              <Feedback
                loggedUser={this.props.loggedUser}
                hide={() => {
                  this.setState({
                    toast: false,
                  });
                }}
                feedbackVisible={(value) => {
                  this.setState({
                    feedbackVisible: value,
                  });
                  console.log("value : " + value);
                }}
              ></Feedback>
            )}
          </div>
        )}

        <div className="selected-menu-item">
          <div className="">{this.state.selectedMenu}</div>
        </div>
        <div className="row clear-margin">
          <div className="col-3 main-menu">
            <div className="row">
              <div className="col project-list">
                <label
                  tabIndex={0}
                  role="presentation"
                  title="Select a project from project list"
                >
                  Project
                </label>
                <select
                  id="project-select-id"
                  className="form-select form-select-sm mb-3"
                  // aria-label=".form-select-sm example"
                  onChange={this.handleProjectDropDownChange}
                  onFocus={this.handleDropdownFocus}
                  onBlur={this.handleDropdownBlur}
                  value={this.state.selectedGTEProjectId}
                  tabIndex={0}
                  role="presentation"
                  title={`${
                    !this.state.isDropdownFocused ? "Expand" : "Collapse"
                  } project list`}
                >
                  {this.state.projects.map((project) => (
                    <option
                      id={project.gteProjectId}
                      key={project.gteProjectId}
                      value={project.gteProjectId}
                      title={project.name}
                    >
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="list-group">
                  <ul className="menu-item-list">
                    <li
                      className={this.bindListGroupClasses("Time")}
                      aria-current="true"
                      onClick={() => this.onClickMenuItem("Time")}
                      title="Time based configiguration"
                      tabIndex={0}
                      onKeyUp={(event) => {
                        if (event.key === "ArrowRight") {
                          this.setState({
                            move: true,
                          });
                        }
                        this.onClickMenuItem("Time");
                      }}
                    >
                      <div>
                        <img src={TimeIcon} alt="" />
                      </div>
                      <span>Time</span>
                    </li>

                    <li
                      className={this.bindListGroupClasses("Task")}
                      onClick={() => this.onClickMenuItem("Task")}
                      tabIndex={0}
                      title="Task based configuration"
                      alt="Task based configuration"
                      onKeyUp={(event) => {
                        if (event.key === "ArrowRight") {
                          this.setState({
                            move: true,
                          });
                        }
                        this.onClickMenuItem("Task");
                      }}
                    >
                      <div>
                        <img src={TaskIcon} alt="" />
                      </div>
                      Task
                    </li>

                    <li
                      className={this.bindListGroupClasses("Comment")}
                      onClick={() => this.onClickMenuItem("Comment")}
                      title="Comment based configiguration"
                      tabIndex={0}
                      onKeyUp={(event) => {
                        if (event.key === "ArrowRight") {
                          this.setState({
                            move: true,
                          });
                        }
                        this.onClickMenuItem("Comment");
                      }}
                    >
                      <div>
                        <img src={CommentIcon} alt="" />
                      </div>
                      Comment
                    </li>

                    <li
                      className={this.bindListGroupClasses("Manage Delegates")}
                      onClick={() => this.onClickMenuItem("Manage Delegates")}
                      title=" Manage Delegates based configiguration"
                      tabIndex={0}
                      onKeyUp={(event) => {
                        if (event.key === "ArrowRight") {
                          this.setState({
                            move: true,
                          });
                        }
                        this.onClickMenuItem("Manage Delegates");
                      }}
                    >
                      <div>
                        <img src={MngIcon} alt="" />
                      </div>
                      Manage Delegates
                    </li>

                    <li
                      className={this.bindListGroupClasses(
                        "Change Time Approver"
                      )}
                      title="Change Time Approver based configiguration"
                      tabIndex={0}
                      onClick={() =>
                        this.onClickMenuItem("Change Time Approver")
                      }
                      onKeyUp={(event) => {
                        if (event.key === "ArrowRight") {
                          this.setState({
                            move: true,
                          });
                        }
                        this.onClickMenuItem("Change Time Approver");
                      }}
                    >
                      <div>
                        <img src={TimeApIcon} alt="" />
                      </div>
                      Change Time Approver
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-9">
            {this.props.gteProject !== 0 ? (
              <div className="detail-block">{this.renderConfigMenu()}</div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div>
          {this.state.error?.status &&
            this.state.error.message.status !== 404 && (
              <ErrorModel
                loggedUser={this.props.loggedUser}
                errorResponse={this.state.error?.message}
                hide={() => {
                  this.setState({
                    error: {
                      status: false,
                      message: "",
                    },
                  });
                }}
              ></ErrorModel>
            )}
        </div>
      </React.Fragment>
    );
  }
}

export default MainMenu;
