import React, { Component } from "react";
import envConfig from "../../environment-property/property.json";
import Model from "./model";
import {
  getErrorMessage,
  getRequestMethod,
  isDuplicate,
  postMethod,
} from "../../services/utils";
import ErrorModel from "../commonComponents/errorModel";

class ManageDelegates extends Component {
  state = {
    model: false,
    RemoveAllModel: false,
    manageDelegate: {},
    teamMembers: [],
    selectedEmployeeName: "",
    selectedEmployeeId: 0,
    isActive: "Y",
    allSelected: false,
    isChanged: false,
    error: {
      status: false,
      message: "",
    },
    loading: false,
    focus: false,
  };
  componentDidMount() {
    this.getManageDelegateConfigData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.gteProject !== this.props.gteProject) {
      this.getManageDelegateConfigData();
    }
    if (prevProps.move !== this.props.move) {
      if (this.props.move) {
        this.setState({
          focus: true,
        });
      }
    }
  }

  /*
   *Read responses and add "isEnable: false, isChanged: false" => required for checkbox selections
   */

  getManageDelegateConfigData = () => {
    //Reset teamMembers array

    this.setState({
      allSelected: false,
      isChanged: false,
      teamMembers: [],
      error: {
        status: false,
        message: "",
      },
    });
    this.callManageDelegateConfigDataAPI();
  };

  callManageDelegateConfigDataAPI = () => {
    getRequestMethod(
      envConfig.services.projectConfigurationBFF,
      "?delegation-type=E&project-id=",
      this.props.gteProject,
      "",
      ""
    )
      .then((response) => {
        this.setState({
          teamMembers: response?.data?.teamMembers?.map((member) => {
            return {
              ...member,
              isEnable: false,
              isChanged: false,
              refreshing: false,
            };
          }),
          allSelected: false,
          isChanged: false,
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  /*
   * use same model for "TimeEntryBulk" and for Single User
   * for Single user-> get payload (delegatee list) for selected Team member
   * Model -> pass values -> selected Team member [selectedEmployeeName ,selectedEmployeeId ] and its payload [manageDelegate]
   */

  timeEntrySingleUserGet = (
    selectedEmployeeName,
    selectedEmployeeId,
    isActive
  ) => {
    this.setState({
      model: false,
      selectedEmployeeName: "",
      manageDelegate: {},
      selectedEmployeeId: 0,
      isActive: isActive,
      error: {
        status: false,
        message: "",
      },
    });

    getRequestMethod(
      envConfig.services.projectConfigurationBFF,
      "-emp?delegation-type=E&is-active=" + isActive + "&project-id=",
      this.props.gteProject,
      "&gte-employee-id=",
      selectedEmployeeId
    )
      .then((response) => {
        this.setState({
          isChanged: true,
          model: true,
          selectedEmployeeName: selectedEmployeeName,
          manageDelegate: response.data,
          selectedEmployeeId: selectedEmployeeId,
          teamMembers: this.state.teamMembers.map((obj) => {
            if (obj.gteEmployeeNo === selectedEmployeeId) {
              return { ...obj, refreshing: true };
            }
            return obj;
          }),
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  /*
   * checkbox [All]->
   * update state of "allSelected", "isChanged" -> required for capturing the state of behavior of checkbox
   *  should update the status of every object's "isEnable" and "isChange"
   */

  handleAllSelectedCheckBoxChangeEvent = (event) => {
    this.setState({
      allSelected: event.target.checked,
      isChanged: event.target.checked,
      teamMembers: this.state.teamMembers.map((obj) => {
        return {
          ...obj,
          isEnable: event.target.checked,
          isChanged: event.target.checked,
        };
      }),
    });
  };

  handleCheckBoxChangeEventofItem = (e, gteEmployeeNo) => {
    this.setState({
      isChanged: true,

      teamMembers: this.state.teamMembers.map((obj) => {
        if (obj.gteEmployeeNo === gteEmployeeNo) {
          return { ...obj, isEnable: e.target.checked, isChanged: true };
        }
        return obj;
      }),
    });
    /**
     * if one item's checkbox -> unselected ->-> [checkbox of All ]-> should be unselected
     */
    if (this.state.allSelected === true) {
      this.setState({
        allSelected: false,
      });
    }
  };

  removeAll = () => {
    let team = [];
    if (this.state.isChanged === true) {
      this.state.teamMembers?.map((obj) => {
        // get selected teammembers id list
        if (obj.isEnable && obj.isChanged) {
          team = [...team, obj.gteEmployeeNo];
        }
      });

      this.timeEntryDelegationRemoval({
        projectId: this.props.gteProject,
        loggedUser: this.props.loggedUser.email,
        delegationType: "D",
        teamMembers: team,
      });
    }
  };

  timeEntryDelegationRemoval = (value) => {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });

    postMethod(
      envConfig.services.projectConfigurationBFFPOST,
      "/delegation",
      "",
      "",
      "",
      value
    )
      .then((response) => {
        this.callManageDelegateConfigDataAPI();
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  /**
   * Open Model for Time Entry Bulk
   */
  timeApproverDelegationBulk = () => {
    let teamMembers = this.state.teamMembers?.map((member) => {
      let delegateeList = []; // define new delegatee list
      if (member.isEnable && member.isChanged) {
        return {
          ...member,
          refreshing: true,
        };
      } else {
        return member;
      }
    });
    this.setState({
      teamMembers: teamMembers,
      selectedEmployeeName: "Add Delegate(s)",
      manageDelegate: this.state.teamMembers,
      model: true,
    });
  };

  cancelSelections = () => {
    if (this.state.isChanged) {
      this.setState({
        allSelected: false,
        isChanged: false,
        teamMembers: this.state.teamMembers.map((obj) => {
          return {
            ...obj,
            isEnable: false,
            isChanged: false,
            refreshing: false,
          };
        }),
      });
    }
  };
  isDuplicate = (data, obj) => {
    return data.some((el) =>
      Object.entries(obj).every(([key, value]) => value === el[key])
    );
  };
  /**
   *
   * @param {*} type -> timeEntryDelegationSave or TimeEntryBulk save
   * @param {*} employeeIdOrUpdateList  -> Single user timeEntryDelegation -> selected team member id || TimeEntryBulk -> get passed payload to capture the selected teammembers
   * @param {*} delegatedTeamMembers -> delegated teammembers list
   *
   * type -> [timeEntryDelegationSave] -> update state of selected Team member
   *  type -> [TimeEntryBulk] -> update -> delegate list [merge new and existed delegate list], isEnable, isChange as false
   */
  updateDelegates = (type, employeeIdOrUpdateList, delegatedTeamMembers) => {
    this.callManageDelegateConfigDataAPI();
  };
  setRefreshingTrue = () => {
    if (this.state.isChanged) {
      let teamMembers = this.state.teamMembers?.map((member) => {
        if (member.isEnable && member.isChanged) {
          return {
            ...member,
            refreshing: true,
          };
        } else {
          return member;
        }
      });

      this.setState({
        teamMembers: teamMembers,
      });
    }
  };
  setRefreshingFalse = () => {
    if (this.state.isChanged) {
      let teamMembers = this.state.teamMembers?.map((member) => {
        if (member.isEnable && member.isChanged) {
          return {
            ...member,
            refreshing: false,
          };
        } else {
          return member;
        }
      });

      this.setState({
        teamMembers: teamMembers,
      });
    }
  };
  renderModelForRemoveAll = () => {
    return (
      <div className="button-with-model">
        {/* <!-- Button trigger modal --> */}

        <button
          tabIndex={0}
          ref={(inputElement) => {
            if (inputElement) {
              if (this.state.focus) {
                inputElement.focus();
              }
            }
          }}
          onKeyDown={() => {
            this.setState({
              focus: false,
            });
            this.props.updateMoveState();
          }}
          type="button"
          className="remove-all-btn"
          data-bs-toggle="modal"
          data-bs-target="#removeAllModal"
          onClick={() => {
            this.setRefreshingTrue();
          }}
          title="Remove All"
        >
          Remove All
        </button>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="removeAllModal"
          aria-labelledby="removeAllModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header bg-dark">
                <button
                  type="button bg-light"
                  className="btn-close bg-outline-light"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    this.cancelSelections();
                  }}
                  title="close"
                ></button>
              </div>

              <div className="modal-body">
                {!this.state.isChanged ? (
                  <p className="text-danger" tabIndex={0}>
                    {" "}
                    &#10153; Please select the delegators to remove{" "}
                  </p>
                ) : (
                  <h5 tabIndex={0}>
                    Remove the added delegates of the selected delegators?
                  </h5>
                )}
              </div>

              <div className="modal-footer">
                {this.state.isChanged === true && (
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      this.removeAll();
                    }}
                    tabIndex={0}
                  >
                    OK
                  </button>
                )}

                <button
                  type="button"
                  className="popup-btn"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    this.cancelSelections();
                  }}
                  tabIndex={0}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-11"></div>
        {this.props.gteProject !== 0 ? (
          <section>
            {/* {this.state.teamMembers !== 0 ? (<div> */}
            {/* <div className="row">
              <div className="col-11">
              </div>
              <div className="col-1">
                <button type="button" className="btn btn-outline-dark">
                  Save
                </button>
              </div>
            </div> */}

            <div className="row">
              <div className="col-6"></div>
              <div className="col-6 align-right">
                {this.renderModelForRemoveAll()}
                <div className="button-with-model">
                  <button
                    type="button"
                    className="bulk-config-btn"
                    onClick={() => this.timeApproverDelegationBulk()}
                    title="Time Entry Bulk"
                  >
                    Time Entry Bulk
                  </button>
                </div>
              </div>
            </div>

            <div className="row select-all">
              <div key="a1" className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.allSelected}
                  id="allSelectedCheckbox"
                  onChange={(e) => this.handleAllSelectedCheckBoxChangeEvent(e)}
                  title={`${
                    this.state.allSelected
                      ? "Selected All Team Members"
                      : "Unselected All Team Members"
                  }`}
                />
                <label className="form-check-label">All</label>
              </div>
            </div>

            <div className="row action-table">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th tabIndex={0}>Team Member</th>
                    <th tabIndex={0}>Delegates</th>
                  </tr>
                </thead>
                {this.state.teamMembers.length !== 0 ? (
                  <tbody>
                    {this.state.teamMembers?.map((member) => {
                      return (
                        <tr key={member.gteEmployeeNo}>
                          <td className="col-5">
                            <div className="form-check">
                              <div className="list-action-set">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={member.isEnable}
                                  id={member.gteEmployeeNo}
                                  title={`${
                                    member.isEnable ? "Selected" : "Unselected"
                                  } ${member.name}`}
                                  onChange={(e) =>
                                    this.handleCheckBoxChangeEventofItem(
                                      e,
                                      member.gteEmployeeNo
                                    )
                                  }
                                />
                                <button
                                  type="button"
                                  className="list-btn btn"
                                  value={member.gteEmployeeNo}
                                  title="Available delegates check"
                                  onClick={() =>
                                    this.timeEntrySingleUserGet(
                                      member.name,
                                      member.gteEmployeeNo,
                                      member.isActive
                                    )
                                  }
                                >
                                  {/* &#187; */}
                                  &#9776;
                                </button>
                              </div>
                              <div className="list-user-set">
                                <label className="form-check-label-input">
                                  <b>{member.employeeNo}</b> {" - "}
                                  {member.name}
                                  {member.isActive === "N" ? "(Ex.)" : ""}
                                </label>
                              </div>
                            </div>
                          </td>
                          <td className="col-7">
                            {member.refreshing ? (
                              <div className="spinner-container">
                                <div className="loading-spinner"></div>
                              </div>
                            ) : (
                              <div
                                className="delegateeList"
                                tabIndex={0}
                                title={member.delegatees?.map(
                                  (item) => item.name
                                )}
                              >
                                {member.delegatees
                                  ?.map((item) => item.name)
                                  .join(", ")}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <div className="notify">Data Fetching</div>
                )}
              </table>
            </div>
          </section>
        ) : (
          <div className="notify h5 text-light">No Data to Display</div>
        )}
        <section>
          {this.state.model === true ? (
            <Model
              popupModel={this.state.model}
              manageDelegate={this.state.manageDelegate}
              selectedEmployeeId={this.state.selectedEmployeeId}
              selectedEmployeeName={this.state.selectedEmployeeName}
              isActive={this.state.isActive}
              gteProject={this.props.gteProject}
              loggedUser={this.props.loggedUser}
              // bulkState={this.state.isChanged} //->1. isChanged === true -> did checkbox selection and click "time Entry bulk",-> 2. isChanged === false -> go to "Time Entry Bulk" without checbox selection
              bulkState={this.state.isChanged}
              updateDelegates={(
                type,
                employeeIdOrUpdateList,
                delegatedTeamMembers
              ) => {
                this.updateDelegates(
                  type,
                  employeeIdOrUpdateList,
                  delegatedTeamMembers
                );
              }}
              loadingState={() => {
                this.setState({ loading: false });
              }}
              hide={() => {
                this.setState({
                  model: false,
                });
              }}
              cancelSelections={() => {
                this.cancelSelections();
              }}
            />
          ) : (
            ""
          )}
        </section>
        {this.state.error?.status &&
          this.state.error.message.status !== 404 && (
            <ErrorModel
              loggedUser={this.props.loggedUser}
              errorResponse={this.state.error?.message}
              hide={() => {
                this.setState({
                  error: {
                    status: false,
                    message: "",
                  },
                });
              }}
            ></ErrorModel>
          )}
      </React.Fragment>
    );
  }
}

export default ManageDelegates;
