import React, { Component } from "react";
import envConfig from "../../environment-property/property.json";
import ToggleSwitch from "../commonComponents/ToggleSwitch";
import {
  getErrorMessage,
  getRequestMethod,
  patchMethod,
} from "../../services/utils";
import ErrorModel from "../commonComponents/errorModel";

class Time extends Component {
  state = {
    timeTypeConfig: {
      configId: -1,
      isEnable: false,
      isChanged: false,
    },
    timeTypes: [],
    enableInvoiceComment: {
      //- required to update -> when do->swich off -> Time Type Button
      configId: 0,
      isEnable: false,
      isChanged: false,
    },
    makeInvoiceCommentsMandatory: {
      //- required to update -> when do->swich off -> Time Type Button
      configId: 0,
      isEnable: false,
      isChanged: false,
    },
    error: {
      status: false,
      message: "",
    },
    focus: false,
  };

  componentDidMount() {
    this.getTimeConfigData();
    this.getCommentsConfigData();
  }

  /**
   * Reload the page when project change
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.gteProject !== this.props.gteProject) {
      this.getTimeConfigData();
      this.getCommentsConfigData();
    }
    if (prevProps.move !== this.props.move) {
      if (this.props.move) {
        this.setState({
          focus: true,
        });
      }
    }
  }

  /**
   *Read responce and if config value is IS_TIME_TYPE_ENABLE store in state isTimeTypeEnable variable
   * or else if config value is TIME_TYPE store in state timeTypes array.
   */
  getTimeConfigData = () => {
    //Should pass project ID {this.props.gteProject}

    //Reset Time type array
    this.setState({
      timeTypes: [],
      error: {
        status: false,
        message: "",
      },
    });

    getRequestMethod(
      envConfig.services.projectConfiguration,
      "?config-type=TIME,TIME_TYPE&project-id=",
      this.props.gteProject,
      "",
      ""
    )
      .then((response) => {
        let timeTypes = this.state.timeTypes;
        response?.data?.map((config) => {
          if (config.projectConfiguration === "IS_TIME_TYPE_ENABLE") {
            this.setState({
              timeTypeConfig: {
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                configId: config.projectConfigPk.projectConfigId,
                isChanged: false,
              },
            });
          } else if (config.attribute3 === "TIME_TYPE") {
            let obj = {
              timeTypeName: config.attribute1,
              isEnable: config.projectConfigBoolValue === 0 ? false : true,
              configId: config.projectConfigPk.projectConfigId,
              isChanged: false,
            };

            timeTypes = [
              ...timeTypes,
              {
                timeTypeName: config.attribute1,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                configId: config.projectConfigPk.projectConfigId,
                isChanged: false,
              },
            ];

            if (timeTypes.length > 0) {
              timeTypes = timeTypes.sort((a, b) =>
                a.configId > b.configId ? 1 : -1
              );
              this.setState({
                timeTypes: timeTypes,
              });
            }
          }
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };
  getCommentsConfigData = () => {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });

    getRequestMethod(
      envConfig.services.projectConfiguration,
      "?config-type=COMMENTS&project-id=",
      this.props.gteProject,
      "",
      ""
    )
      .then((response) => {
        response?.data?.map((config) => {
          if (config.projectConfiguration === "IS_INV_COMMENT_ENABLE") {
            this.setState({
              enableInvoiceComment: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          } else if (
            config.projectConfiguration === "INV_COMMENT_MANDATORY_ENABLE"
          ) {
            this.setState({
              makeInvoiceCommentsMandatory: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          }
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };
  // handleTimeTypeSwitches = (event) => {
  //   const timeTypeConfig = this.state.timeTypeConfig;
  //   timeTypeConfig.isEnable = event.target.checked;
  //   timeTypeConfig.isChanged = true;
  //   if (!event.target.checked) {
  //     this.setState({
  //       timeTypes: this.state.timeTypes.map((timeType) =>
  //         timeType.timeTypeName === "Normal" && timeType.isEnable
  //           ? timeType
  //           : { ...timeType, isEnable: false }
  //       ),
  //       timeTypeConfig,
  //     });
  //   } else {
  //     this.setState({ timeTypeConfig });
  //   }
  //   this.handleSave();
  // };

  handleCheckBoxChangeEvent = (event, configID) => {
    this.setState({
      timeTypes: this.state.timeTypes?.map((timeType) =>
        timeType.configId === configID
          ? { ...timeType, isEnable: event.target.checked, isChanged: true }
          : timeType
      ),
    });

    this.state.timeTypes?.map((timeType) => {
      if (timeType.configId === configID) {
        this.updateTimeConfigData(this.props.gteProject, timeType.configId, {
          projectConfigBoolValue: event.target.checked ? 1 : 0,
        });
      }
    });
  };

  handleSave = () => {
    if (this.state.timeTypeConfig?.isChanged) {
      this.updateTimeConfigData(
        this.props.gteProject,
        this.state.timeTypeConfig.configId,
        { projectConfigBoolValue: this.state.timeTypeConfig.isEnable ? 1 : 0 }
      );
      /**
       * handleTimeTypeSwitches => if false {off} =>should be unselected all checkboxes except normal
       */
      if (!this.state.timeTypeConfig?.isEnable) {
        this.state.timeTypes.map((timeType) => {
          if (timeType.timeTypeName !== "Normal" && timeType.isEnable) {
            this.updateTimeConfigData(
              this.props.gteProject,
              timeType.configId,
              {
                projectConfigBoolValue: 0,
              }
            );
          }
        });
      }
    }

    if (this.state.makeInvoiceCommentsMandatory.isChanged) {
      this.updateTimeConfigData(
        this.props.gteProject,
        this.state.makeInvoiceCommentsMandatory.configId,
        {
          projectConfigBoolValue: this.state.makeInvoiceCommentsMandatory
            .isEnable
            ? 1
            : 0,
        }
      );
    }
    if (this.state.enableInvoiceComment.isChanged) {
      this.updateTimeConfigData(
        this.props.gteProject,
        this.state.enableInvoiceComment.configId,
        {
          projectConfigBoolValue: this.state.enableInvoiceComment.isEnable
            ? 1
            : 0,
        }
      );
    }

    /**
     * The below code will be required in future-> if we use save button, without using at onchange of checkbox selection
     *
     */

    // this.state.timeTypes.map((timeType) => {
    //   if (timeType.isChanged) {
    //     this.updateTimeConfigData(this.props.gteProject, timeType.configId, {
    //       projectConfigBoolValue: timeType.isEnable ? 1 : 0,
    //     });
    //   }
    // });
  };

  updateTimeConfigData = (project, configId, value) => {
    patchMethod(
      envConfig.services.projectConfiguration,
      "/",
      this.props.loggedUser?.email,
      "/",
      project,
      "/",
      configId,
      value
    )
      .then((response) => {})
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  handleTimeTypeSwitches = (currentEvent) => {
    const timeTypeConfig = this.state.timeTypeConfig;
    timeTypeConfig.isEnable = currentEvent;
    timeTypeConfig.isChanged = true;
    if (!currentEvent) {
      this.setState({
        timeTypes: this.state.timeTypes?.map((timeType) =>
          timeType.timeTypeName === "Normal" && timeType.isEnable
            ? timeType
            : { ...timeType, isEnable: false }
        ),
        timeTypeConfig,
      });

      /**
       * check the states of isEnable of enableInvoiceComment and makeInvoiceCommentsMandatory
       * if isEnable === true -> make status changes of isEnable -> false and isChanged ->true -> for API call to update comments config
       */

      if (this.state.enableInvoiceComment.isEnable) {
        let enableInvoiceComment = this.state.enableInvoiceComment;
        enableInvoiceComment.isEnable = false;
        enableInvoiceComment.isChanged = true;
        this.setState({
          enableInvoiceComment: enableInvoiceComment,
        });
      }
      if (this.state.makeInvoiceCommentsMandatory.isEnable) {
        let makeInvoiceCommentsMandatory =
          this.state.makeInvoiceCommentsMandatory;
        makeInvoiceCommentsMandatory.isEnable = false;
        makeInvoiceCommentsMandatory.isChanged = true;
        this.setState({
          makeInvoiceCommentsMandatory: makeInvoiceCommentsMandatory,
        });
      }
    } else {
      this.setState({ timeTypeConfig });
    }
    this.handleSave();
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-11"></div>
        <div>
          {/* <div className="row">
            <div className="col-11">
            </div>
            <div className="col-1 detail-action">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => {
                  this.handleSave();
                  this.getTimeConfigData();
                }}
              >
                Save
              </button>
            </div>
          </div> */}
          <div className="detail-header">
            <span
              className="header-text"
              title="Time Types"
              tabIndex={0}
              ref={(inputElement) => {
                if (inputElement) {
                  if (this.state.focus) {
                    inputElement.focus();
                  }
                }
              }}
              onKeyDown={() => {
                this.setState({
                  focus: false,
                });
                this.props.updateMoveState();
              }}
            >
              Time Types
            </span>
            <ToggleSwitch
              // title={`${
              //   this.state.timeTypeConfig.isEnable ? "Select" : "Unselect"
              // }`}
              tabIndex={0}
              label="Time Types"
              checked={this.state.timeTypeConfig.isEnable}
              handleSwich={this.handleTimeTypeSwitches}
            />
          </div>

          <div className="form-block">
            <label
              className="form-check-label-input"
              title="Pick Your Project's Time Types"
              tabIndex={0}
            >
              Pick Your Project's Time Types
            </label>
            {this.state.timeTypes.map((timeType) => (
              <div key={timeType.configId} className="form-check">
                <input
                  className="form-check-input"
                  disabled={
                    timeType.timeTypeName === "Normal"
                      ? true
                      : false || this.state.timeTypeConfig.isEnable === false
                      ? true
                      : false
                  }
                  type="checkbox"
                  checked={
                    timeType.timeTypeName === "Normal"
                      ? true
                      : timeType.isEnable
                  }
                  id="flexCheckDefault"
                  onChange={(e) =>
                    this.handleCheckBoxChangeEvent(e, timeType.configId)
                  }
                  title={`${timeType?.isEnable ? "Select" : "Unselect"} ${
                    timeType?.timeTypeName
                  }`}
                />
                <label
                  className="form-check-label"
                  title={timeType.timeTypeName}
                >
                  {timeType.timeTypeName}
                </label>
              </div>
            ))}
          </div>
        </div>
        {/* // )} */}

        {this.state.error?.status &&
          this.state.error.message.status !== 404 && (
            <ErrorModel
              loggedUser={this.props.loggedUser}
              errorResponse={this.state.error?.message}
              hide={() => {
                this.setState({
                  error: {
                    status: false,
                    message: "",
                  },
                });
              }}
            ></ErrorModel>
          )}
      </React.Fragment>
    );
  }
}

export default Time;
