import React, { Component } from "react";
import CheckInLogo from "../../logo/gte_logo.png";

class notFound extends Component {
  render() {
    return (
      <>
       <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" 
          role="presentation">
            <img
              src={CheckInLogo}
              tabIndex={0}
              role="presentation"
              alt="Checking - Project Configuration"
            />
          </a>
          <div className="d-flex logged-user">
           
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
            
            </div>
          </div>
        </div>
      </nav>
        <div className="center_nf">
          <div className="row inline-row">
            <div className="f04">
              404: The page you requested could not be found.
            </div>
            <div className="pnf">
              <a href="/" className="pnfA">Home</a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default notFound;
