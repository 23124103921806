import React, { Component } from "react";
import "./ToggleSwitch.css";

// const ToggleSwitch = ({ label, checked, handleSwich, disabled }) => {
class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="toggle-switch"
          tabIndex={0}
           title={`${this.props.checked ? "On" : "Off"}`}
        >
          <input
            tabIndex={0}
            // title={`${this.props.checked ? "On" : "Off"}`}
            type="checkbox"
            className="checkbox"
            role="switch"
            disabled={this.props.disabled}
            name={this.props.label}
            id={this.props.label}
            checked={this.props.checked}
            onClick={(e) => {
              this.props.handleSwich(e.target.checked);
            }}
            onChange={(e) => {
              this.props.handleSwich(e.target.checked);
            }}
          
          />
          <label className="label" htmlFor={this.props.label}>
            <span className="inner" />
            <span className="switch" />
          </label>
        </div>
      </React.Fragment>
    );
  }
}

export default ToggleSwitch;