import React, { Component } from "react";
import { Toast, Form } from "react-bootstrap";
import { getloggedUserDetails, sendFeedback } from "../../services/utils";

class feedback extends Component {
  state = {
    toast: true,
    selectedOption: "",
    feedbackVisible: !document.cookie.includes("chk_pc_feedback=true"),
  };

  onValueChange(event) {
    // console.log(event.target.value);
    this.setState({
      selectedOption: event.target.value,
    });
    if (event.target.value === "Take me back to previous version") {
      this.setState({
        selectedOption: event.target.value,
      });
      this.formSubmit("Take me back to previous version");
    }
  }

  formSubmit(selectedOption) {
    selectedOption = selectedOption.trim();
    if (selectedOption.length > 0) {
      let value = {
        applicationCode: "Checkin-Web-Revamp",

        functionality: "Project-Configuration",

        ratings: 0,

        feedbackBy: getloggedUserDetails()?.userName,

        ratingsCategory: selectedOption,

        ratingsComment: selectedOption,
      };
      let user_id = this.props.loggedUser;
      this.setState({
        toast: false,
      });

      sendFeedback(user_id, value);
      document.cookie =
        "chk_pc_feedback=true; expires=Tue, 31 Dec 4712 23:59:59 GMT";
      this.props.hide();
      this.props.feedbackVisible(false);
    }
  }
  render() {
    const { feedbackVisible } = this.state;
    return (
      <div
        style={{
          position: "fixed",
          bottom: 20,
          right: 10,
          zIndex: 9999,
          float: "right",
        }}
      >
        <Toast
          className="feedback"
          onClose={() => {
            this.setState({
              toast: false,
            });
            this.props.hide();
            this.props.feedbackVisible(false);
          }}
        >
          <Toast.Header closeButton={true} >
            <img src="" className="rounded me-2" alt="" />
            <strong className="me-auto" tabIndex={0}>
              How would you rate your experience with the Improved Version of
              CheckIn?
            </strong>
            {/* <button type="button" className="btn-close" aria-label="Close" data-dismiss="toast"></button> */}
          </Toast.Header>
          <Toast.Body>
            {/* <Form> */}
            <div>
              <div
                className="form-check form-check-inline"
                // onSubmit={this.formSubmit}
                tabIndex={0}
              >
                <input
                tabIndex={0}
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio1"
                  value="Very Satisfied"
                  title="Very Satisfied"
                  checked={this.state.selectedOption === "Very Satisfied"}
                  onChange={(event) => {
                    this.onValueChange(event);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Very Satisfied
                </label>
              </div>
              <br />
              <div className="form-check form-check-inline"    tabIndex={0}>
                <input
               tabIndex={0}
                  title="Satisfied"
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio2"
                  value="Satisfied"
                  checked={this.state.selectedOption === "Satisfied"}
                  onChange={(event) => {
                    this.onValueChange(event);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio2" >
                  Satisfied
                </label>
              </div>
              <br />
              <div className="form-check form-check-inline"    tabIndex={0}>
                <input
                tabIndex={0}
                  title="Neutral"
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio3"
                  value="Neutral"
                  checked={this.state.selectedOption === "Neutral"}
                  onChange={(event) => {
                    this.onValueChange(event);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  Neutral
                </label>
              </div>
              <br />
              <div className="form-check form-check-inline"   tabIndex={0}>
                <input
                 tabIndex={0}
                  title="Unsatisfied"
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio4"
                  value="Unsatisfied"
                  checked={this.state.selectedOption === "Unsatisfied"}
                  onChange={(event) => {
                    this.onValueChange(event);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio4">
                  Unsatisfied
                </label>
              </div>
              {/* <div className="form-check form-check-inline">
                <a href="https://checkin-config.virtusa.com" target="_self"
                rel="noopener noreferrer"
                >
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio5"
                  value="Take me back to previous version"
                  checked={
                    this.state.selectedOption ===
                    "Take me back to previous version"
                  }
                  onChange={(event) => {
                    this.onValueChange(event);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio5">
                  Take me back to previous version
                </label>
              </div> */}
            </div>
            {/* </Form> */}
            <div>
              <hr></hr>
            </div>
            <button
              tabIndex={0}
              title="Submit"
              onClick={() => {
                this.formSubmit(this.state.selectedOption);
              }}
              class="btn modal-button"
            >
              {" "}
              Submit
            </button>
            {/* {this.state.selectedOption !==
              "Take me back to previous version" && (
              <button
                onClick={() => {
                  this.formSubmit();
                }}
              >
                {" "}
                Submit
              </button>
            )} */}
          </Toast.Body>
        </Toast>
      </div>
    );
  }
}
export default feedback;
