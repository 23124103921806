import React, { Component } from "react";
import "./App.css";
import "./Feedback.css";
import NavBar from "./components/navbar";
import MainMenu from "./components/mainMenu";
import envConfig from "./environment-property/property.json";
import AuthService from "./services/AuthService";
import { getloggedUserDetails, setAccessToken } from "./services/utils";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loggedUser: null,
      isAuthenticated: false,
      isLoading: true,
    };
    this.AuthService = new AuthService();
    this.tokenCacheKey = envConfig.authConfiguration.tokenCacheKey;
  }

  async componentDidMount() {
    try {
      let params = new URL(document.location).searchParams;

      if (params != null && params.get("jwt") != null) {
        let jwt;
        jwt = params.get("jwt");
        setAccessToken(jwt);
        this.setState({
          isAuthenticated: true,
          isLoading: false,
          loggedUser: {
            displayName: getloggedUserDetails().name,
            email: getloggedUserDetails().userName,
          },
        });
      } else {
        const login = await this.AuthService.initLogin();
        if (login) {
          const accessToken = await this.AuthService.getToken();

          setAccessToken(accessToken);

          if (envConfig.debug.isDebugEnable) {
            //let params = new URL(document.location).searchParams;

            let displayName;
            let email;

            if (params != null) {
              displayName = params.get("employee");
              email = params.get("user-name");

              this.setState({
                isAuthenticated: true,
                isLoading: false,
                loggedUser: {
                  displayName: displayName,
                  email: email,
                },
              });
            } else {
              this.setState({
                loggedUser: envConfig.debug.defaultUser,
              });
            }
          } else {
            this.setState({
              isAuthenticated: true,
              isLoading: false,
              loggedUser: {
                displayName: getloggedUserDetails().name,
                email: getloggedUserDetails().userName,
              },
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleLogin = () => {
    this.AuthService.login();
  };
  handleLogOut = () => {
    this.setState({ loggedUser: null });
    //Azure AD logout to be implement
  };

  mainComponent = () => {
    if (this.state.loggedUser !== null) {
      return (
        <MainMenu key="main-menu-key" loggedUser={this.state.loggedUser} />
      );
    } else {
      return (
        <div className="alert alert-light" role="alert">
          {/* You are logout! Please login. */}
        </div>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <NavBar
          key="nav-bar-key"
          loggedUser={this.state.loggedUser}
          logOut={this.handleLogOut}
          logIn={this.handleLogIn}
        />
        <main className="container-fluid">{this.mainComponent()}</main>
      </React.Fragment>
    );
  }
}

export default App;
