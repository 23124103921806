import React, { Component } from "react";
import CheckInLogo from "../logo/gte_logo.png";

class NavBar extends Component {
  renderLogInAndLogoutOption = () => {
    if (this.props.loggedUser == null) {
      return (
        <li className="nav-item">
          <button
            className="btn btn-outline-success"
            type="submit"
            onClick={this.props.logIn}
          >
            Log in
          </button>
        </li>
      );
    } else {
      return (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title={this.props.loggedUser.displayName}
          >
            {this.props.loggedUser.displayName}
          </a>
          <ul className="dropdown-menu">
            <li>
              <a
                className="dropdown-item"
                title="Home"
                onClick={this.props.logOut}
                href="https://ecgy.fa.ap2.oraclecloud.com:443/fscmUI/faces/FuseWelcome"
              >
                Home
              </a>
            </li>
          </ul>
        </li>
      );
    }
  };

  render() {
    return (
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" 
          role="presentation">
            <img
              src={CheckInLogo}
              tabIndex={0}
              role="presentation"
              alt="Checking - Project Configuration"
            />
          </a>
          <div className="d-flex logged-user">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {this.renderLogInAndLogoutOption()}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
