import React, { Component } from "react";
import envConfig from "../../environment-property/property.json";
import {
  getErrorMessage,
  getRequestMethod,
  patchMethod,
} from "../../services/utils";
import ToggleSwitch from "../commonComponents/ToggleSwitch";
import ErrorModel from "../commonComponents/errorModel";

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableInvoiceComment: {
        configId: 0,
        isEnable: false,
        isChanged: false,
      },
      makeInvoiceCommentsMandatory: {
        configId: 0,
        isEnable: false,
        isChanged: false,
      },
      makeInternalCommentsMandatory: {
        configId: 0,
        isEnable: false,
        isChanged: false,
      },
      timeTypeConfig: {
        isEnable: false,
      },
      // configValue: getConfigValue(),
      timeTypeConfigisEnable: false,
      loaded: false, // required -> this page related to tim page switch button config
      error: {
        status: false,
        message: "",
      },
      focus: false,
    };
  }

  componentDidMount() {
    this.getCommentsConfigData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.gteProject !== this.props.gteProject) {
      this.getCommentsConfigData();
    }
    if (prevProps.move !== this.props.move) {
      if (this.props.move) {
        this.setState({
          focus: true,
        });
      }
    }
  }

  getCommentsConfigData = () => {
    this.setState({
      timeTypeConfigisEnable: false,
      error: {
        status: false,
        message: "",
      },
    });

    getRequestMethod(
      envConfig.services.projectConfiguration,
      "?config-type=COMMENTS&project-id=",
      this.props.gteProject,
      "",
      ""
    )
      .then((response) => {
        this.getTimeConfigData();
        response.data.map((config) => {
          if (config.projectConfiguration === "IS_INV_COMMENT_ENABLE") {
            this.setState({
              loaded: true,
              enableInvoiceComment: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          } else if (
            config.projectConfiguration === "INV_COMMENT_MANDATORY_ENABLE"
          ) {
            this.setState({
              makeInvoiceCommentsMandatory: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          } else if (
            config.projectConfiguration === "INTERNAL_COMMENT_MANDATORY_ENABLE"
          ) {
            this.setState({
              makeInternalCommentsMandatory: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          }
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  /**
   * getTimeConfigData()-> required to check the value of IS_TIME_TYPE_ENABLE ->
   * if false -> Enable Invoice Comments & Make Invoice Comments Mandatory swiches -> should be disable and OFF
   */
  getTimeConfigData = () => {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });
    getRequestMethod(
      envConfig.services.projectConfiguration,
      "?config-type=TIME,TIME_TYPE&project-id=",
      this.props.gteProject,
      "",
      ""
    )
      .then((response) => {
        response.data.map((config) => {
          if (config.projectConfiguration === "IS_TIME_TYPE_ENABLE") {
            this.setState({
              timeTypeConfigisEnable:
                config.projectConfigBoolValue === 0 ? false : true,
            });
          }
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  // handleEnableInvoiceCommentSwitches = (event) => {
  //   const enableInvoiceComment = this.state.enableInvoiceComment;
  //   enableInvoiceComment.isEnable = event.target.checked;
  //   enableInvoiceComment.isChanged = true;
  //   this.setState({ enableInvoiceComment });
  //   this.handleSave();
  // };
  handleEnableInvoiceCommentSwitches_2 = (eventValue) => {
    const enableInvoiceComment = this.state.enableInvoiceComment;
    enableInvoiceComment.isEnable = eventValue;
    enableInvoiceComment.isChanged = true;

    const makeInvoiceCommentsMandatory =
      this.state.makeInvoiceCommentsMandatory;
    if (!eventValue) {
      makeInvoiceCommentsMandatory.isEnable = eventValue;
      makeInvoiceCommentsMandatory.isChanged = true;
    }

    this.setState({ enableInvoiceComment, makeInvoiceCommentsMandatory });
    this.handleSave();
  };

  handleMakeInvoiceCommentsMandatorySwitches_2 = (eventValue) => {
    const makeInvoiceCommentsMandatory =
      this.state.makeInvoiceCommentsMandatory;
    makeInvoiceCommentsMandatory.isEnable = eventValue;
    makeInvoiceCommentsMandatory.isChanged = true;
    this.setState({ makeInvoiceCommentsMandatory });
    this.handleSave();
  };

  handleMakeInternalCommentsMandatorySwitches_2 = (eventValue) => {
    const makeInternalCommentsMandatory =
      this.state.makeInternalCommentsMandatory;
    makeInternalCommentsMandatory.isEnable = eventValue;
    makeInternalCommentsMandatory.isChanged = true;
    this.setState({ makeInternalCommentsMandatory });
    this.handleSave();
  };
  handleSave = () => {
    if (this.state.enableInvoiceComment.isChanged) {
      this.updateCommentsConfigData(
        this.props.gteProject,
        this.state.enableInvoiceComment.configId,
        {
          projectConfigBoolValue: this.state.enableInvoiceComment.isEnable
            ? 1
            : 0,
        },
        "enableInvoiceComment"
      );
    }
    if (this.state.makeInvoiceCommentsMandatory.isChanged) {
      this.updateCommentsConfigData(
        this.props.gteProject,
        this.state.makeInvoiceCommentsMandatory.configId,
        {
          projectConfigBoolValue: this.state.makeInvoiceCommentsMandatory
            .isEnable
            ? 1
            : 0,
        },
        "makeInvoiceCommentsMandatory"
      );
    }
    if (this.state.makeInternalCommentsMandatory.isChanged) {
      this.updateCommentsConfigData(
        this.props.gteProject,
        this.state.makeInternalCommentsMandatory.configId,
        {
          projectConfigBoolValue: this.state.makeInternalCommentsMandatory
            .isEnable
            ? 1
            : 0,
        },
        "makeInternalCommentsMandatory"
      );
    }
  };

  updateCommentsConfigData = (project, configId, value, type) => {
    patchMethod(
      envConfig.services.projectConfiguration,
      "/",
      this.props.loggedUser?.email,
      "/",
      project,
      "/",
      configId,
      value
    )
      .then((response) => {
        if (type === "enableInvoiceComment") {
          const enableInvoiceComment = this.state.enableInvoiceComment;
          enableInvoiceComment.isChanged = false;
          this.setState({ enableInvoiceComment });
        } else if (type === "makeInvoiceCommentsMandatory") {
          const makeInvoiceCommentsMandatory =
            this.state.makeInvoiceCommentsMandatory;
          makeInvoiceCommentsMandatory.isChanged = false;
          this.setState({ makeInvoiceCommentsMandatory });
        } else if (type === "makeInternalCommentsMandatory") {
          const makeInternalCommentsMandatory =
            this.state.makeInternalCommentsMandatory;
          makeInternalCommentsMandatory.isChanged = false;
          this.setState({ makeInternalCommentsMandatory });
        }
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-11"></div>
        <div className="col-11"></div>
        <div>
          {/* <div className="row">
            <div className="col-11">{this.state.error.status === true ? (<div className="alert alert-warning text-danger" role="alert">{this.state.error.message}</div>) : ('')}</div>
            <div className="col-1">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => this.handleSave()}
              >
                Save
              </button>
            </div>
          </div> */}

          <div className="detail-header">
            <span
              className="header-text"
              title="Enable Invoice Comment"
              tabIndex={0}
              ref={(inputElement) => {
                if (inputElement) {
                  if (this.state.focus) {
                    inputElement.focus();
                  }
                }
              }}
              onKeyDown={() => {
                this.setState({
                  focus: false,
                });
                this.props.updateMoveState();
              }}
            >
              Enable Invoice Comment
            </span>

            <ToggleSwitch
              label="Enable Invoice Comment"
              disabled={
                !this.state.loaded || !this.state.timeTypeConfigisEnable
              }
              checked={this.state.enableInvoiceComment.isEnable}
              handleSwich={this.handleEnableInvoiceCommentSwitches_2}
            />
          </div>
          <div className="detail-info-block">
            <div className="col-11">
              <div
                tabIndex={0}
                title="You can enable this feature if you need to allow users to enter
              comments for the time they submit for tasks that have to be shown
              to the clients in your invoices. Works on specific invoice
              templates only"
              >
                You can enable this feature if you need to allow users to enter
                comments for the time they submit for tasks that have to be
                shown to the clients in your invoices. Works on specific invoice
                templates only.
              </div>
            </div>
          </div>

          <div className="detail-header">
            <span
              className="header-text"
              title="Make Invoice Comments Mandatory"
              tabIndex={0}
            >
              Make Invoice Comments Mandatory
            </span>
            <ToggleSwitch
              label="Make Invoice Comments Mandatory"
              disabled={
                !this.state.loaded ||
                !this.state.enableInvoiceComment.isEnable ||
                !this.state.timeTypeConfigisEnable
              }
              checked={this.state.makeInvoiceCommentsMandatory.isEnable}
              handleSwich={this.handleMakeInvoiceCommentsMandatorySwitches_2}
            />
          </div>
          <div className="detail-info-block">
            <div className="col-11">
              <div
                tabIndex={0}
                title="You can enable this if it is a must to include users’ comments in
              the time entries that are sent to clients. Please note that if
              this feature is switched on, users will not be able to submit time
              unless the invoice comment is entered by them"
              >
                You can enable this if it is a must to include users’ comments
                in the time entries that are sent to clients. Please note that
                if this feature is switched on, users will not be able to submit
                time unless the invoice comment is entered by them.
              </div>
            </div>
          </div>

          <div className="detail-header">
            <span
              className="header-text"
              title=" Make Internal Comments Mandatory"
              tabIndex={0}
            >
              Make Internal Comments Mandatory
            </span>
            <ToggleSwitch
              label="Make Internal Comments Mandatory"
              disabled={true}
              checked={this.state.makeInternalCommentsMandatory.isEnable}
              handleSwich={this.handleMakeInternalCommentsMandatorySwitches_2}
            />
          </div>
          <div>
            <div className="detail-info-block">
              <div
                tabIndex={0}
                title="You can enable this, if you require an internal comment for all
              the time entries of the users. Please note that if this feature is
              switched on, users will not be able to submit time unless the
              internal comment is entered by them"
              >
                You can enable this, if you require an internal comment for all
                the time entries of the users. Please note that if this feature
                is switched on, users will not be able to submit time unless the
                internal comment is entered by them.
              </div>
            </div>
          </div>
        </div>
        {this.state.error?.status &&
          this.state.error.message.status !== 404 && (
            <ErrorModel
              loggedUser={this.props.loggedUser}
              errorResponse={this.state.error?.message}
              hide={() => {
                this.setState({
                  error: {
                    status: false,
                    message: "",
                  },
                });
              }}
            ></ErrorModel>
          )}
      </React.Fragment>
    );
  }
}

export default Comment;
