import envConfig from "../../environment-property/property.json";
import React, { Component, Fragment } from "react";
import { getErrorMessage, postMethod } from "../../services/utils";

class Model extends Component {
  state = {
    delegatedTeamMembers: [],
    availableTeamMembers: [],
    initialAvailableTeamMembers: [],
    initialDelegatedTeamMembers: [],
    deletedDelegatees: [],
    manageDelegate: [],
    error: {
      status: false,
      message: "",
    },
    initialState: true,
  };
  componentDidMount() {
    this.getDelegatednAvailableList();
  }

  getDelegatednAvailableList = () => {
    let delegatedTeamMembers = this.state.delegatedTeamMembers;
    let availableTeamMembers = this.state.availableTeamMembers;
    let initialDelegatedTeamMembers = this.state.initialAvailableTeamMembers;
    let initialAvailableTeamMembers = this.state.initialAvailableTeamMembers;

    this.setState({
      manageDelegate: this.props.manageDelegate,
      error: {
        status: false,
        message: "",
      },
    });

    let delegatees = [];
    //TimeEntryBulk -> passsed complete payload of [this.state.teamMembers in manageDelegates UI] -> filter delegate list
    if (this.props.selectedEmployeeName === "Add Delegate(s)") {
      delegatees = this.props.manageDelegate
        .filter((parameter) => parameter.isActive.includes("Y"))
        ?.map((member) => {
          return {
            gteEmployeeNo: member.gteEmployeeNo,
            name: member.name,
            isSelected: "N",
          };
        });
    } else {
      // Single user -> only passsed delegate list
      delegatees = this.props.manageDelegate.delegatees.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    }

    delegatees?.map((member) => {
      if (member.isSelected === "Y") {
        delegatedTeamMembers = [
          ...delegatedTeamMembers,
          { gteEmployeeNo: member.gteEmployeeNo, name: member.name },
        ];
        initialDelegatedTeamMembers = [
          ...initialDelegatedTeamMembers,
          { gteEmployeeNo: member.gteEmployeeNo, name: member.name },
        ];
      }
      if (member.isSelected === "N") {
        availableTeamMembers = [
          ...availableTeamMembers,
          { gteEmployeeNo: member.gteEmployeeNo, name: member.name },
        ];
        initialAvailableTeamMembers = [
          ...initialAvailableTeamMembers,
          { gteEmployeeNo: member.gteEmployeeNo, name: member.name },
        ];
      }
    });

    this.setState({
      delegatedTeamMembers: delegatedTeamMembers.sort((a, b) =>
        a.name > b.name ? 1 : -1
      ),
      availableTeamMembers: availableTeamMembers.sort((a, b) =>
        a.name > b.name ? 1 : -1
      ),
      initialDelegatedTeamMembers: initialDelegatedTeamMembers.sort((a, b) =>
        a.name > b.name ? 1 : -1
      ),
      initialAvailableTeamMembers: initialAvailableTeamMembers.sort((a, b) =>
        a.name > b.name ? 1 : -1
      ),
    });
  };

  /**
   * handle shift items between available list n delegate list
   * [Object.keys(obj).length === 0] -> handle shiftAll buttons
   * else -> single item click
   */
  shift = (type, obj) => {
    let availableTeamMembers = this.state.availableTeamMembers;
    let delegatedTeamMembers = this.state.delegatedTeamMembers;

    if (type === "availableTeamMembers") {
      if (Object.keys(obj).length === 0) {
        availableTeamMembers.map((obj) => {
          availableTeamMembers = this.arrayFiltering(availableTeamMembers, obj);
          if (!this.isDuplicate(delegatedTeamMembers, obj)) {
            delegatedTeamMembers = [...delegatedTeamMembers, obj];
          }
        });
      } else {
        availableTeamMembers = this.arrayFiltering(availableTeamMembers, obj);
        if (!this.isDuplicate(delegatedTeamMembers, obj)) {
          delegatedTeamMembers = [...delegatedTeamMembers, obj];
        }
      }
    }
    if (type === "delegatedTeamMembers") {
      if (Object.keys(obj).length === 0) {
        delegatedTeamMembers.map((obj) => {
          delegatedTeamMembers = this.arrayFiltering(delegatedTeamMembers, obj);
          if (!this.isDuplicate(availableTeamMembers, obj)) {
            availableTeamMembers = [...availableTeamMembers, obj];
          }
        });
      } else {
        delegatedTeamMembers = this.arrayFiltering(delegatedTeamMembers, obj);
        if (!this.isDuplicate(availableTeamMembers, obj)) {
          availableTeamMembers = [...availableTeamMembers, obj];
        }
      }
    }

    this.setState({
      availableTeamMembers: availableTeamMembers.sort((a, b) =>
        a.name > b.name ? 1 : -1
      ),
      delegatedTeamMembers: delegatedTeamMembers.sort((a, b) =>
        a.name > b.name ? 1 : -1
      ),
    });
  };

  isDuplicate = (data, obj) => {
    return data.some((el) =>
      Object.entries(obj).every(([key, value]) => value === el[key])
    );
  };

  arrayFiltering = (data, obj) => {
    return data.filter((member) => {
      return member.gteEmployeeNo !== obj.gteEmployeeNo;
    });
  };

  refreshItems = () => {
    this.setState({
      availableTeamMembers: this.state.initialAvailableTeamMembers,
      delegatedTeamMembers: this.state.initialDelegatedTeamMembers,
    });
  };
  /**
   * required to get initialy existed,but then deleted delegate list
   */
  getDeletedDelegatees = () => {
    this.setState({ deletedDelegatees: [] });
    let initialDelegatedTeamMembers = this.state.initialDelegatedTeamMembers; // existed Delegate list
    let delegatedTeamMembers = this.state.delegatedTeamMembers.sort((a, b) =>
      a.name > b.name ? 1 : -1
    ); // new updated Delegate list

    //logic to find deleted one->loop the initial list, one by one compare with new delegated list
    //-> isDuplicate -> false -> it has existed but now its not in the list  <- its deleted item
    if (initialDelegatedTeamMembers.length) {
      initialDelegatedTeamMembers.map((obj) => {
        if (!this.isDuplicate(delegatedTeamMembers, obj)) {
          this.state.deletedDelegatees = [...this.state.deletedDelegatees, obj];
        }
      });
    }
  };

  saveInModel = () => {
    if (this.props.selectedEmployeeName === "Add Delegate(s)") {
      this.timeEntryBulkSave();
    } else {
      this.timeEntryDelegationSave();
    }
  };

  timeEntryBulkSave = () => {
    let teamMembers = [];
    let manageDelegate;
    // if (this.props.bulkState) {
    manageDelegate = this.state.manageDelegate?.map((obj) => {
      if (obj.isEnable && obj.isChanged) {
        teamMembers = [...teamMembers, obj.gteEmployeeNo];
        return { ...obj };
      }
      return obj;
    });
    this.saveTimeEntryBulk(teamMembers);
    // } else {
    //   manageDelegate = this.state.manageDelegate?.map((obj) => {
    //     teamMembers = [...teamMembers, obj.gteEmployeeNo];
    //     return { ...obj };
    //   });
    //   this.saveTimeEntryBulk(teamMembers);
    // }
  };

  saveTimeEntryBulk = (teamMembers) => {
    this.props.hide();
    let delegatees = this.state.delegatedTeamMembers?.map(
      ({ gteEmployeeNo }) => {
        return gteEmployeeNo;
      }
    );
    let value = {
      projectId: this.props.gteProject,
      loggedUser: this.props.loggedUser.email,
      delegationType: "E",
      teamMembers: teamMembers,
      delegatees: delegatees,
    };
    postMethod(
      envConfig.services.projectConfigurationBFFPOST,
      "/delegation",
      "",
      "",
      "",
      value
    )
      .then((response) => {
        this.props.updateDelegates(
          this.props.bulkState,
          this.state.manageDelegate,
          this.state.delegatedTeamMembers
        );
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          // loading: false,
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  timeEntryDelegationSave = () => {
    this.getDeletedDelegatees();

    let value = {
      loggedUser: this.props.loggedUser.email,
      delegation: "E",
      projectId: this.props.gteProject,
      teamMemberGteEmployeeId: this.props.selectedEmployeeId,
      isActive: this.props.isActive,
      delegatees: [
        {
          delegationType: "D",
          gteEmployeeNos: this.state.deletedDelegatees.map(
            ({ gteEmployeeNo }) => {
              return gteEmployeeNo;
            }
          ),
        },
        {
          delegationType: "E",
          gteEmployeeNos: this.state.delegatedTeamMembers?.map(
            ({ gteEmployeeNo }) => {
              return gteEmployeeNo;
            }
          ),
        },
      ],
    };

    this.updateManageDelegateeData(value);
    this.props.hide();
  };

  updateManageDelegateeData = (value) => {
    postMethod(
      envConfig.services.projectConfigurationBFFPOST,
      "/delegation",
      "",
      "",
      "",
      value
    )
      .then((response) => {
        this.props.updateDelegates(
          "timeEntryDelegationSave",
          value.teamMemberGteEmployeeId,
          this.state.delegatedTeamMembers
        );
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  render() {
    let modelStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };

    return (
      <Fragment>
        <div>
          <div className="modal show fade" style={modelStyle}>
            <div className="modal-dialog modal-width">
              <div className="modal-content">
                <div
                  className="modal-header"
                  tabIndex={0}
                  ref={(inputElement) => {
                    if (inputElement) {
                      if (this.state.initialState && this.props.popupModel) {
                        console.log("ref");
                        inputElement.focus();
                      }
                    }
                  }}
                >
                  {this.props.selectedEmployeeName} | Available Delegates
                  <button
                    type="button"
                    title="Close Available Delegate Popup"
                    className="btn-close"
                    onClick={() => {
                      this.props.hide();
                      this.props.cancelSelections();
                    }}
                  ></button>
                </div>

                {this.props.bulkState ||
                this.props.selectedEmployeeName !== "Add Delegate(s)" ? (
                  <div>
                    {" "}
                    <div className="modal-body">
                      <div className="row modal-title">
                        <div
                          className="col-8 mx-auto"
                          tabIndex={0}
                          role="presentation"
                          title="Available Delegates"
                        >
                          Available Delegates
                        </div>

                        <div
                          className="col-4 mx-auto"
                          tabIndex={0}
                          role="presentation"
                          title="Delegated Team Members"
                        >
                          Delegated Team Members
                        </div>
                      </div>

                      <div className="row shift-list-block">
                        <div className="shift-list">
                          <div>
                            <ul className="list-group list-group-flush">
                              {this.state.availableTeamMembers?.map(
                                (availableTeamMember) => {
                                  return (
                                    <li>
                                      <button
                                        className="list-group-item list-group-item-action listButtonRight"
                                        key={availableTeamMember.gteEmployeeNo}
                                        title={`Move ${availableTeamMember.name} to delegated list`}
                                        onClick={() =>
                                          this.shift(
                                            "availableTeamMembers",
                                            availableTeamMember
                                          )
                                        }
                                      >
                                        {availableTeamMember.name}{" "}
                                        <span className="">&#10095;</span>
                                      </button>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>

                        <div className="shift-list-action">
                          <div>
                            <button
                              type="button"
                              className=""
                              title="Reset members"
                              onClick={() => this.refreshItems()}
                            >
                              Reset &#8272;
                            </button>

                            <button
                              type="button"
                              className=""
                              title="Shift all members to delegated list"
                              key="shiftAllToDelegatedList"
                              onClick={() =>
                                this.shift("availableTeamMembers", {})
                              }
                            >
                              {/* Shift All to DelegatedList */}
                              Shift All &#187;
                            </button>
                            <button
                              type="button"
                              className=""
                              title="Shift all members to available list"
                              key="shiftAllToAvailableList"
                              onClick={() =>
                                this.shift("delegatedTeamMembers", {})
                              }
                            >
                              {/* Shift All to Available List */}
                              &#171; Shift All
                            </button>
                          </div>
                        </div>

                        <div className="shift-list">
                          <div>
                            <ul className="list-group list-group-flush">
                              {this.state.delegatedTeamMembers?.map(
                                (delegatedTeamMember) => {
                                  return (
                                    <li>
                                      <button
                                        className="list-group-item list-group-item-action "
                                        key={delegatedTeamMember.gteEmployeeNo}
                                        title={`Move ${delegatedTeamMember.name} to available list`}
                                        onClick={() =>
                                          this.shift(
                                            "delegatedTeamMembers",
                                            delegatedTeamMember
                                          )
                                        }
                                      >
                                        <span className="left-arrow">
                                          &#10094;
                                        </span>{" "}
                                        <label className="left-name">
                                          {delegatedTeamMember.name}
                                        </label>
                                      </button>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        title="Save available delegates"
                        className="btn modal-button"
                        onClick={() => this.saveInModel()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <h5
                      className="text-danger text-center"
                      tabIndex={0}
                      alt="You have not selected any Team Member"
                       role="presentation"
                    >
                      You have not selected any Team Member
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Model;
