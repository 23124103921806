import React, { Component } from "react";
import envConfig from "../../environment-property/property.json";
import {
  getErrorMessage,
  getRequestMethod,
  patchMethod,
} from "../../services/utils";
import ToggleSwitch from "../commonComponents/ToggleSwitch";
import ErrorModel from "../commonComponents/errorModel";

class Task extends Component {
  state = {
    unAssignTask: {
      configId: 0,
      isEnable: false,
      isChanged: false,
    },
    addTask: {
      configId: 0,
      isEnable: false,
      isChanged: false,
    },
    copyUnassignTask: {
      configId: 0,
      isEnable: false,
      isChanged: false,
    },
    error: {
      status: false,
      message: "",
    },
    taskDissable: false,
    projectTypeStatus: false,
    leadByStatus: false,
    focus: false,
  };

  componentDidMount() {
    this.getTaskConfigData();
    this.setDissableStatus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.gteProject !== this.props.gteProject) {
      this.getTaskConfigData();
      this.setDissableStatus();
    }
    if (prevProps.move !== this.props.move) {
      if (this.props.move) {
        this.setState({
          focus: true,
        });
      }
    }
  }

  getTaskConfigData = () => {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });

    getRequestMethod(
      envConfig.services.projectConfiguration,
      "?config-type=TASK&project-id=",
      this.props.gteProject,
      "",
      ""
    )
      .then((response) => {
        response.data.map((config) => {
          if (config.projectConfiguration === "TASK_ASSIGNMENT_ENABLED") {
            this.setState({
              unAssignTask: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          } else if (config.projectConfiguration === "ADD_TASK_ENABLED") {
            this.setState({
              addTask: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          } else if (
            config.projectConfiguration === "COPY_UNASSIGN_TASK_ENABLED"
          ) {
            this.setState({
              copyUnassignTask: {
                configId: config.projectConfigPk.projectConfigId,
                isEnable: config.projectConfigBoolValue === 0 ? false : true,
                isChanged: false,
              },
            });
          }
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  setDissableStatus = () => {
    let leadByStatus = false;
    let projectTypeStatus = false;
    this.props.projectTypeConfig?.map((config) => {
      if (config.configValue === this.props.selectedProject?.leadBy) {
        leadByStatus = true;
      }
    });
    this.props.projectTemplate?.map((template) => {
      if (template.configValue === this.props.selectedProject?.projectType) {
        projectTypeStatus = true;
      }
    });

    this.setState({
      taskDissable: leadByStatus || projectTypeStatus ? false : true,
      projectTypeStatus: projectTypeStatus,
      leadByStatus: leadByStatus,
    });
  };

  // handleUnAssignTaskEnableSwitches = (event) => {
  //   const unAssignTask = this.state.unAssignTask;
  //   unAssignTask.isEnable = event.target.checked;
  //   unAssignTask.isChanged = true;
  //   this.setState({ unAssignTask });
  //   this.handleSave();
  // };
  handleUnAssignTaskEnableSwitches_2 = (eventValue) => {
    const unAssignTask = this.state.unAssignTask;
    unAssignTask.isEnable = eventValue;
    unAssignTask.isChanged = true;

    const copyUnassignTask = this.state.copyUnassignTask;
    if (!eventValue) {
      copyUnassignTask.isEnable = eventValue;
      copyUnassignTask.isChanged = true;
    }

    this.setState({ unAssignTask });
    this.handleSave();
  };

  // handleAddTaskEnableSwitches = (event) => {
  //   const addTask = this.state.addTask;
  //   addTask.isEnable = event.target.checked;
  //   addTask.isChanged = true;
  //   this.setState({ addTask });
  //   this.handleSave();
  // };
  handleAddTaskEnableSwitches_2 = (eventValue) => {
    const addTask = this.state.addTask;
    addTask.isEnable = eventValue;
    addTask.isChanged = true;
    this.setState({ addTask });
    this.handleSave();
  };
  handleCopyUnAssignTaskEnableSwitches_2 = (eventValue) => {
    const copyUnassignTask = this.state.copyUnassignTask;
    copyUnassignTask.isEnable = eventValue;
    copyUnassignTask.isChanged = true;
    this.setState({ copyUnassignTask });
    this.handleSave();
  };
  handleSave = () => {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });
    if (this.state.unAssignTask.isChanged) {
      this.updateTaskConfigData(
        this.props.gteProject,
        this.state.unAssignTask.configId,
        { projectConfigBoolValue: this.state.unAssignTask.isEnable ? 1 : 0 },
        "unAssignTask"
      );
    }
    if (this.state.addTask.isChanged) {
      this.updateTaskConfigData(
        this.props.gteProject,
        this.state.addTask.configId,
        { projectConfigBoolValue: this.state.addTask.isEnable ? 1 : 0 },
        "addTask"
      );
    }
    if (this.state.copyUnassignTask.isChanged) {
      this.updateTaskConfigData(
        this.props.gteProject,
        this.state.copyUnassignTask.configId,
        {
          projectConfigBoolValue: this.state.copyUnassignTask.isEnable ? 1 : 0,
        },
        "copyUnassignTask"
      );
    }
  };

  updateTaskConfigData = (project, configId, value, type) => {
    patchMethod(
      envConfig.services.projectConfiguration,
      "/",
      this.props.loggedUser?.email,
      "/",
      project,
      "/",
      configId,
      value
    )
      .then((response) => {
        if (type === "unAssignTask") {
          const unAssignTask = this.state.unAssignTask;
          unAssignTask.isChanged = false;
          this.setState({ unAssignTask });
        } else if ("addTask") {
          const addTask = this.state.addTask;
          addTask.isChanged = false;
          this.setState({ addTask });
        } else if ("copyUnassignTask") {
          const copyUnassignTask = this.state.copyUnassignTask;
          copyUnassignTask.isChanged = false;
          this.setState({ copyUnassignTask });
        }
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-11"></div>
        {this.props.gteProject !== 0 ? (
          <div>
            {/* <div className="row">
          <div className="col-11">
          
            </div>
          <div className="col-1">
            <button type="button" 
            className="btn btn-outline-dark"
            onClick={() => this.handleSave()}
            >
              Save
            </button>
          </div>
        </div> */}
            <div className="detail-header">
              <span className="header-text" title="Unassigned Task Model"  tabIndex={0}
              ref={(inputElement) => {
                if (inputElement) {
                  if (this.state.focus) {
                    inputElement.focus();
                  }
                }
              }}
              onKeyDown={() => {
                this.setState({
                  focus: false,
                });
                this.props.updateMoveState();
              }}>
                Unassigned Task Model
              </span>
              <ToggleSwitch
                label="Unassigned Task Model"
                title="Unassigned Task Model"
                disabled={this.state.taskDissable}
                checked={this.state.unAssignTask.isEnable}
                handleSwich={this.handleUnAssignTaskEnableSwitches_2}
              />
            </div>
            <div className="row detail-info-block">
              <div className="col-6">
                <p title="if Unassigned Task Model Enabled" tabIndex={0}>
                  if Enabled
                </p>
                <div
                  tabIndex={0}
                  title="It will allow users to self-assign tasks, enter, save and
                  submt time against them"
                >
                  It will allow users to self-assign tasks, enter, save and
                  submt time against them
                </div>
              </div>
              <div className="col-6">
                <p tabIndex={0} title="if Unassigned Task Model Disabled">
                  if Disabled
                </p>
                <div
                  tabIndex={0}
                  title=" The employees will only have tasks that are directly assigned by
                me"
                >
                  The employees will only have tasks that are directly assigned
                  by me
                </div>
              </div>
            </div>
            <div className="detail-header">
              <span className="header-text" title="Add Task" tabIndex={0}>
                Add Task
              </span>
              <ToggleSwitch
                label="Add Task"
                disabled={true}
                checked={this.state.addTask.isEnable}
                handleSwich={this.handleAddTaskEnableSwitches_2}
              />
            </div>
            <div className="row detail-info-block">
              <div
                className="col-12"
                tabIndex={0}
                title=" By turning this feature on, you will be allowing your team
                members to create their own tasks. This is suitable if your
                project can include tasks that don't have to be planned and
                scheduled. The only downside is that, you will not be able to
                track these tasks through your project plan / MPP."
              >
                By turning this feature on, you will be allowing your team
                members to create their own tasks. This is suitable if your
                project can include tasks that don't have to be planned and
                scheduled. The only downside is that, you will not be able to
                track these tasks through your project plan / MPP.
              </div>
            </div>

            <div className="detail-header">
              <span
                className="header-text"
                title="Copy Self-Assigned Tasks from Unassigned Module"
                tabIndex={0}
              >
                Copy Self-Assigned Tasks from Unassigned Module
              </span>
              <ToggleSwitch
                disabled={!this.state.unAssignTask.isEnable}
                label="Copy Self-Assigned Tasks from Unassigned Module"
                checked={this.state.copyUnassignTask.isEnable}
                handleSwich={this.handleCopyUnAssignTaskEnableSwitches_2}
              />
            </div>
            <div className="row detail-info-block">
              <div
                className="col-12"
                tabIndex={0}
                title=" This feature will enable the Users of the Project to copy the
                previous week's self-assigned tasks from the unassigned module
                to the current week. Please note that this feature only copies
                the tasks and not the time. This is only to be activated for the
                Projects under the Offerings Department."
              >
                This feature will enable the Users of the Project to copy the
                previous week's self-assigned tasks from the unassigned module
                to the current week. Please note that this feature only copies
                the tasks and not the time. This is only to be activated for the
                Projects under the Offerings Department.
              </div>
            </div>
          </div>
        ) : (
          <div className="notify h5 text-light">No Data to Display</div>
        )}
        {this.state.error?.status &&
          this.state.error.message.status !== 404 && (
            <ErrorModel
              loggedUser={this.props.loggedUser}
              errorResponse={this.state.error?.message}
              hide={() => {
                this.setState({
                  error: {
                    status: false,
                    message: "",
                  },
                });
              }}
            ></ErrorModel>
          )}
      </React.Fragment>
    );
  }
}

export default Task;
